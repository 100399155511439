.newHeading{
    text-align: left;
    background-image: url(https://source.unsplash.com/Zm2n2O7Fph4/1600x900); 
    background-repeat: repeat; 
    -webkit-background-clip: text;  
    -webkit-text-fill-color: transparent; 
    /* margin-top: 200px;  */
    font-size: 150px; 
    font-weight: bold; 
    text-transform: uppercase;
    font-weight: 800; 
    -webkit-font-smoothing: antialiased; 
}

.background{
    background-image: url(https://source.unsplash.com/Zm2n2O7Fph4/1600x900); 
    background-size: cover;
    background-position: center;
}

@media (max-width: 800px){
    .newHeading{
        font-size: 95px;    
    }
}
.heading{
    text-align: left;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0;
}   

.content{
    text-align: left;
}

.underline{
    height: 8px;
    width: 35px;
    background-color: #4CAF50;
    margin-bottom: 14px;
    border-radius: 25px;
}