.logo{
    width: 60px;
    background-color: white;
    border-radius: 100px;
    padding: 5px;
}

.subtitle{
    margin-top: -4px;
    letter-spacing: 2px;
    border-top: 1px solid white;
    margin-left: 10px;
    font-size: 16px;
}