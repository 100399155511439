body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a,a:hover,a:active{
    text-decoration: none;
    color: inherit;
}
