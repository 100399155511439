.heading{
    text-align: left;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0;
}  

.content{
    text-align: left;
}

.underline{
    height: 8px;
    width: 35px;
    background-color: #4CAF50;
    margin-bottom: 14px;
    border-radius: 25px;
}

@media (max-width: 768px){
    .heading{
        font-size: 35px;
        margin-top: 6rem;
        font-size: 2.2rem;
    }

    .content{
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }

    .underline{
     width: 32px;   
    }

    .notiContainer{
        margin: 0 0.5rem !important;
    }
}